import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import ax from './lib/ax';

const defaultPurchaseRequestItem = {
    id: 0,
    maker: '',
    name: '',
    standard: '',
    amount: 0,
    unit: '',
    shippingAddress: '',
    remarks: '',
    attachedFile: null,
    attachedFileName: '',
    attachedFileUrl: '',
};

class PurchaseRequest extends React.Component {
    static defaultProps = {
        id: 0,
        defaultShippingAddress: '',
        editingItem: {...defaultPurchaseRequestItem}
    }

    constructor(props) {
        super(props);
        this.state = {
            id: props.id * 1,
            items: [],
            newItemCount: 0,
            showModal: false,
        }
    }

    componentDidMount() {
        if (this.state.id == 0) return false;
        ax.get(`/purchase_requests/${this.state.id}/items.json`).then((response) => {
            const newItems = this.state.items.slice();
            response.data.map((item) => {
                newItems.push(item);
            });
            this.setState({items: newItems});
        });
    }

    clearEditingItem() {
        this.props.editingItem.id = defaultPurchaseRequestItem.id;
        this.props.editingItem.maker = defaultPurchaseRequestItem.maker;
        this.props.editingItem.name = defaultPurchaseRequestItem.name;
        this.props.editingItem.standard = defaultPurchaseRequestItem.standard;
        this.props.editingItem.amount = defaultPurchaseRequestItem.amount;
        this.props.editingItem.unit = defaultPurchaseRequestItem.unit;
        this.props.editingItem.shippingAddress = defaultPurchaseRequestItem.shippingAddress;
        this.props.editingItem.attachedFileName = defaultPurchaseRequestItem.attachedFileName;
        this.props.editingItem.attachedFileUrl = defaultPurchaseRequestItem.attachedFileUrl;
        this.props.editingItem.remarks = defaultPurchaseRequestItem.remarks;
    }

    handleEditButtonClick(e) {
        const itemId = e.target.dataset.itemId;
        const selectorPrefix = `[data-item-id="${itemId}"] >`;
        const attachedFileLink = document.querySelector(`${selectorPrefix} td[data-item-prop-name="attached-file"] > a`);
        this.props.editingItem.id = itemId;
        this.props.editingItem.maker = document.querySelector(`${selectorPrefix} td[data-item-prop-name="maker"]`).textContent;
        this.props.editingItem.name = document.querySelector(`${selectorPrefix} td[data-item-prop-name="name"]`).textContent;
        this.props.editingItem.standard = document.querySelector(`${selectorPrefix} td[data-item-prop-name="standard"]`).textContent;
        this.props.editingItem.amount = document.querySelector(`${selectorPrefix} td[data-item-prop-name="amount"]`).textContent;
        this.props.editingItem.unit = document.querySelector(`${selectorPrefix} td[data-item-prop-name="unit"]`).textContent;
        this.props.editingItem.shippingAddress = document.querySelector(`${selectorPrefix} td[data-item-prop-name="shipping-address"]`).textContent;
        this.props.editingItem.remarks = document.querySelector(`${selectorPrefix} td[data-item-prop-name="remarks"]`).textContent;
        this.props.editingItem.attachedFileName = attachedFileLink ? attachedFileLink.dataset.name : "";
        this.props.editingItem.attachedFileUrl = attachedFileLink ? attachedFileLink.attributes.href.value : "";
        this.setState({showModal: true});
    }

    handleDeleteButtonClick(e) {
        if (window.confirm("削除しますか？")) {
            const itemId = e.target.dataset.id;
            ax.delete(`/purchase_requests/${this.state.id}/items/${itemId}.json`).then((response) => {
                const items = this.state.items.filter(item => item.id != itemId);
                this.setState({
                    items: items
                });
            });
        }
    }

    handleCancelButtonClick() {
        this.setState({showModal: false});
    }

    handleOnChange(e) {
        this.props.editingItem[e.target.name] = e.target.value;
    }

    handleOnFileChange(e) {
        this.props.editingItem.attachedFile = e.target.files[0];
    }

    validateItem() {
        const currentShippingAddress = document.getElementById("shippingAddress").value;
        this.props.editingItem.shippingAddress = currentShippingAddress;
        const errors = [];
        if (this.props.editingItem.maker.length == 0) errors.push("メーカーを入力して下さい。");
        if (this.props.editingItem.name.length == 0) errors.push("品名を入力して下さい。");
        if (this.props.editingItem.standard.length == 0) errors.push("規格を入力して下さい。");
        if (this.props.editingItem.unit.length == 0) errors.push("単位を入力して下さい。");
        if (this.props.editingItem.amount * 1 == 0) errors.push("数量は0以上を入力して下さい。");
        if (this.props.editingItem.shippingAddress.length == 0) errors.push("納品場所を入力して下さい。");
        return errors;
    }

    afterSave(response) {
        const item = response.data.item;
        const newItem = {
            id: item.id,
            maker: item.maker,
            name: item.name,
            standard: item.standard,
            amount: item.amount,
            unit: item.unit,
            shippingAddress: item.shipping_address,
            remarks: item.remarks,
            attachedFileName: item.attachedFileName,
            attachedFileUrl: item.attachedFileUrl,
        }
        const newItems = this.state.items.slice();
        if (item.isNewRecord == 1) {
            newItems.push(newItem);
        } else {
            newItems.forEach((e, i) => {
                if (e.id == item.id) {
                    console.log(`item id = ${item.id} AND id = ${e.id}`);
                    newItems.splice(i, 1, newItem);
                }
            });
        }
        this.clearEditingItem();
        this.setState({
            id: item.purchase_request_id,
            items: newItems,
            newItemCount: 0,
            showModal: false
        });
    }

    handleSaveButtonClick(e) {
        const errors = this.validateItem();
        if (errors.length > 0) {
            window.alert(errors.join("\n"));
            return false;
        }
        const formData = new FormData();
        formData.append('id', this.props.editingItem.id);
        formData.append('item_maker', this.props.editingItem.maker);
        formData.append('item_name', this.props.editingItem.name);
        formData.append('standard', this.props.editingItem.standard);
        formData.append('amount', this.props.editingItem.amount);
        formData.append('unit', this.props.editingItem.unit);
        formData.append('shipping_address', this.props.editingItem.shippingAddress);
        formData.append('remarks', this.props.editingItem.remarks);
        if (this.props.editingItem.attachedFile != null) {
            formData.append('attached_file', this.props.editingItem.attachedFile);
        }
        if (this.props.editingItem.id == 0) {
            ax.post(`/purchase_requests/${this.state.id}/items.json`, formData)
                .then((response) => {
                    this.afterSave(response)
                });
        } else {
            ax.put(`/purchase_requests/${this.state.id}/items/${this.props.editingItem.id}.json`, formData)
                .then((response) => {
                    this.afterSave(response)
                });
        }
    }

    renderAddButton() {
        return (
            <div className="mt-4 d-flex justify-content-center">
                <button className="btn btn-sm btn-outline-primary" onClick={this.handleAddButtonClick.bind(this)}>
                    <i className="fas fa-plus-circle"></i> 商品追加
                </button>
            </div>
        )
    }

    handleAddButtonClick() {
        this.clearEditingItem();
        this.setState({showModal: true});
    }

    handleDeleteAttachedFileButtonClick() {
        if (!window.confirm("添付ファイルの削除は即実行され、元に戻せません。\n本当に削除しますか？")) {
            return false;
        }
        ax.delete(`/purchase_requests/${this.state.id}/items/${this.props.editingItem.id}/attached_file.json`).then((response) => {
            const items = this.state.items.slice();
            const newItems = items.map(i => {
                if (i.id == this.props.editingItem.id) {
                    return {
                        id: i.id,
                        maker: i.maker,
                        name: i.name,
                        standard: i.standard,
                        amount: i.amount,
                        unit: i.unit,
                        shippingAddress: i.shippingAddress,
                        remarks: i.remarks,
                        attachedFile: null,
                        attachedFileName: '',
                        attachedFileUrl: '',
                    };
                } else {
                    return i;
                }
            });
            this.props.editingItem.attachedFileUrl = defaultPurchaseRequestItem.attachedFileUrl;
            this.props.editingItem.attachedFileName = defaultPurchaseRequestItem.attachedFileUrl;
            this.props.editingItem.attachedFile = defaultPurchaseRequestItem.attachedFile;
            this.setState({
                items: newItems,
                showModal: true
            });
        });
    }

    renderSubmitButton() {
        return (
            <a href={`/purchase_requests/${this.state.id}/submissions/new`}
               className="btn btn-primary">申請
            </a>
        )
    }

    attachedFileComponent() {
        if (this.props.editingItem.attachedFileUrl.length > 0) {
            return (
              <React.StrictMode>
                  <a href={this.props.editingItem.attachedFileUrl}
                     target="_blank"
                     className="d-inline-block me-2">{this.props.editingItem.attachedFileName}
                  </a>
                  <button className="btn btn-sm btn-outline-danger"
                          type="button"
                          onClick={this.handleDeleteAttachedFileButtonClick.bind(this)}>削除
                  </button>
              </React.StrictMode>
            );
        } else {
            return (
              <input type="file" className="form-control" id="attachedFile" name="attachedFile"
                     onChange={this.handleOnFileChange.bind(this)}/>
            );
        }
    }

    render() {
        return (
            <React.StrictMode>
                <div className="card mt-5">
                    <div className="card-body">
                        <table className="table table-hover">
                            <thead>
                            <tr className="bg-light">
                                <th>メーカー</th>
                                <th>品名</th>
                                <th>規格</th>
                                <th>数量</th>
                                <th>単位</th>
                                <th>納品場所</th>
                                <th>備考</th>
                                <th>添付</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.items.map((item, index) => {
                                    return (
                                        <tr key={index} data-item-id={item.id}>
                                            <td data-item-prop-name="maker">{item.maker}</td>
                                            <td data-item-prop-name="name">{item.name}</td>
                                            <td data-item-prop-name="standard">{item.standard}</td>
                                            <td data-item-prop-name="amount">{item.amount}</td>
                                            <td data-item-prop-name="unit">{item.unit}</td>
                                            <td data-item-prop-name="shipping-address">{item.shippingAddress}</td>
                                            <td data-item-prop-name="remarks">{item.remarks}</td>
                                            <td data-item-prop-name="attached-file">{item.attachedFileName ?
                                              <a href={item.attachedFileUrl} target="_blank" data-name={item.attachedFileName}><i className="fas fa-paperclip text-secondary"></i></a> : ''}
                                            </td>
                                            <td className="w-5">
                                                <div className="d-flex justify-content-center">
                                                    <button type="button" className="btn btn-sm btn-link text-primary"
                                                            data-item-id={item.id}
                                                            onClick={this.handleEditButtonClick.bind(this)}>
                                                        <i className="fas fa-pen"></i>
                                                    </button>
                                                    <button type="button" className="btn btn-sm btn-link text-danger"
                                                            data-id={item.id}
                                                            onClick={this.handleDeleteButtonClick.bind(this)}>
                                                        <i className="far fa-trash-alt"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                        {this.state.newItemCount == 0 && this.renderAddButton()}
                    </div>
                    <div className="card-footer bg-white p-3">
                        <div className="d-flex justify-content-center">
                            {this.state.id != 0 && this.renderSubmitButton()}
                            <a href="/" className="btn btn-light ms-2">戻る</a>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={this.state.showModal}
                    contentLabel="発注伝票明細"
                    style={{
                        overlay: {
                            backgroundColor: "rgba(100, 100, 100, 0.7)"
                        },
                        content: {
                            width: "50%",
                            margin: "auto",
                            position: "sticky"
                        }
                    }}
                >
                    <form>
                        <div className="mb-3 row">
                            <div className="col-sm-3 text-end">
                                <label htmlFor="maker" className="form-label required">メーカー</label>
                            </div>
                            <div className="col-sm-9">
                                <input type="text" className="form-control" id="maker" name="maker" autoFocus={true}
                                       onChange={this.handleOnChange.bind(this)}
                                       defaultValue={this.props.editingItem.maker}/>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <div className="col-sm-3 text-end">
                                <label htmlFor="name" className="form-label required">品名</label>
                            </div>
                            <div className="col-sm-9">
                                <input type="text" className="form-control" id="name" name="name"
                                       onChange={this.handleOnChange.bind(this)}
                                       defaultValue={this.props.editingItem.name}/>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <div className="col-sm-3 text-end">
                                <label htmlFor="standard" className="form-label required">規格（品番・型番）</label>
                            </div>
                            <div className="col-sm-9">
                                <input type="text" className="form-control" id="standard" name="standard"
                                       onChange={this.handleOnChange.bind(this)}
                                       defaultValue={this.props.editingItem.standard}/>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <div className="col-sm-3 text-end">
                                <label htmlFor="amount" className="form-label required">数量</label>
                            </div>
                            <div className="col-sm-9">
                                <input type="number" className="form-control" id="amount" name="amount" min = "0"
                                       onChange={this.handleOnChange.bind(this)}
                                       defaultValue={this.props.editingItem.amount}/>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <div className="col-sm-3 text-end">
                                <label htmlFor="unit" className="form-label required">単位</label>
                            </div>
                            <div className="col-sm-9">
                              <select name="unit" onChange={this.handleOnChange.bind(this)} className="form-select">
                                <option value="">--- 選択して下さい ---</option>
                                <option value="バラ(個・枚など)">バラ(個・枚など)</option>
                                <option value="ケース(箱・袋など)">ケース(箱・袋など)</option>
                              </select>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <div className="col-sm-3 text-end">
                              <label htmlFor="shippingAddress" className="form-label required">納品場所</label>
                            </div>
                            <div className="col-sm-9">
                              <input type="text" className="form-control" id="shippingAddress" name="shippingAddress"
                                     onChange={this.handleOnChange.bind(this)}
                                     defaultValue={this.props.editingItem.shippingAddress || this.props.defaultShippingAddress}/>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <div className="col-sm-3 text-end">
                              <label htmlFor="remarks" className="form-label">備考</label>
                            </div>
                            <div className="col-sm-9">
                              <input type="text" className="form-control" id="remarks" name="remarks"
                                     onChange={this.handleOnChange.bind(this)}
                                     defaultValue={this.props.editingItem.remarks}/>
                            </div>
                        </div>
                        <div className="mb-5 row">
                            <div className="col-sm-3 text-end">
                                <label htmlFor="attachedFile" className="form-label">添付ファイル</label>
                            </div>
                            <div className="col-sm-9">
                                {this.attachedFileComponent()}
                            </div>
                        </div>
                        <div className="row g-2 justify-content-end">
                            <div className="col-auto">
                                <button type="button" className="btn btn-primary"
                                        onClick={this.handleSaveButtonClick.bind(this)}>保存
                                </button>
                            </div>
                            <div className="col-auto">
                                <button type="button" className="btn btn-light"
                                        onClick={this.handleCancelButtonClick.bind(this)}>キャンセル
                                </button>
                            </div>
                        </div>
                    </form>
                </Modal>
            </React.StrictMode>
        )
    }
}

document.addEventListener('turbolinks:load', () => {
    const componentContainer = document.getElementById("purchase-request-root");
    if (componentContainer) {
        Modal.setAppElement(componentContainer);
        ReactDOM.render(
            <PurchaseRequest id={componentContainer.dataset.id}
                             defaultShippingAddress={componentContainer.dataset.shippingAddress}/>,
            componentContainer,
        );
    }
});
